<template>
  <div>

    <div class="form">
      <el-form :inline="true" label-width="80px" :model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="form.productName" clearable placeholder="请输入产品名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品类别" prop="productCategory">
              <el-select v-model="form.productCategory" clearable placeholder="请选择产品类别">
                <el-option v-for="item in $store.state.category"
                           :key="item.dictKey"
                           :label="item.dictValue"
                           :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品状态" prop="productStatus">
              <el-select v-model="form.productStatus" clearable placeholder="请选择产品状态">
                <el-option label="未上架" value="0"></el-option>
                <el-option label="上架" value="1"></el-option>
                <el-option label="下架" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" @click="onSubmit(form)">查询</el-button>
            </el-form-item>
          </el-col>
<!--          <el-col :span="7">-->
<!--            <el-form-item label="产品类型" prop="productType">-->
<!--              <el-select v-model="form.productType" clearable placeholder="请选择产品类型">-->
<!--                <el-option label="单品" value="0"></el-option>-->
<!--                <el-option label="套餐" value="1"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
        </el-row>
      </el-form>
    </div>


    <div class="list" style="min-height: calc( 100vh - 214px )" >
      <div class="operation">
        <el-button @click="addClick" size="small" type="primary">添加产品</el-button>
      </div>

      <el-table
          :data="table"
          v-loading="tableLoad"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="width: 100%">
        <el-table-column
            prop="productName"
            align="center"
            label="产品名称">
          <template slot-scope="scope">
            <span class="blue" @click="deta(scope.row)" >{{ scope.row.productName }}</span>
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="username"-->
<!--            align="center"-->
<!--            label="产品图片">-->
<!--          <template slot-scope="scope">-->
<!--            <el-image-->
<!--                style="width: 70px; height: 70px"-->
<!--                :src="scope.row"-->
<!--                :preview-src-list="[scope.row]">-->
<!--            </el-image>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column
            prop="price"
            align="center"
            label="价格">
        </el-table-column>
        <el-table-column
            prop="productCategory"
            align="center"
            label="产品类别">
          <template slot-scope="scope">
            <span>{{ $store.state.category.filter((item) => { return item.dictKey == scope.row.productCategory })[0].dictValue }}</span>
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="productType"-->
<!--            align="center"-->
<!--            label="产品类型">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.productType == 0" >单品</span>-->
<!--            <span v-if="scope.row.productType == 1" >套餐</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column
            prop="productStatus"
            align="center"
            label="产品状态">
          <template slot-scope="scope">
            <span v-if="scope.row.productStatus == 0" >未上架</span>
            <span v-if="scope.row.productStatus == 1" >上架</span>
            <span v-if="scope.row.productStatus == 2" >下架</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            align="center"
            label="添加时间">
        </el-table-column>
<!--        <el-table-column-->
<!--            fixed="right"-->
<!--            align="center"-->
<!--            label="操作">-->
<!--          <template slot-scope="scope">-->
<!--              <el-button type="text" size="small" @click="del(scope.row)" >删除</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>


  </div>
</template>

<script>
export default {
  name: "Product",
  data() {
    return{
      form: {
        pageNum: 1,
        pageSize: 10,
        productCategory: null,
        productName: null,
        productStatus: null,
        productType: null,
      },
      table: [],
      total: 0,
      tableLoad: false,
    }
  },
  created() {
    this.get_list(this.form)
  },
  mounted() {

  },
  methods: {
    // 查询
    onSubmit(formName) {
      formName.pageNum= 1;
      this.get_list(formName);
    },
    // 添加
    addClick() {
      this.$router.push({
        path: "/ProductDeta"
      })
    },
    // 编辑
    deta(e) {
      this.$router.push({
        path: "/ProductDeta",
        query: {
          id: e.productId
        }
      })
    },
    // 删除
    del() {

    },
    handleCurrentChange(e) {
      console.log(`第${e}页`);
      this.form.pageNum= e;
      this.get_list(this.form);
    },
    handleSizeChange(e) {
      console.log(`每页${e}条`);
      this.form.pageNum= 1;
      this.form.pageSize= e;
      this.get_list(this.form);
    },
    get_list(y) {
      this.tableLoad= true;
      this.$get("product-manager",y)
        .then(res => {
          if(res) {
            console.log(res);
            this.tableLoad= false;
            this.total= res.data.total;
            this.table= res.data.list;
          }
        })
    },
  }
}
</script>

<style scoped>

</style>
